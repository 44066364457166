import * as React from 'react';
import { Box } from '@mui/material';
import ChatInput from './ChatInput';
import Messages, { Message } from './Messages';
import * as chatApi from '../api/chat';

export default function Chat() {
    const [disabled, setDisabled] = React.useState<boolean>(false);
    const [messages, setMessages] = React.useState<Message[]>([
        { role: "bot", text: "Hello! I'm the assistant. Send me the name of an art piece and let's talk about it." },
    ]);

    const onMessageSend = async (message: string) => {
        const conversation: chatApi.Conversation = {
            messages: [
                ...messages.reduce<chatApi.Message[]>((acc, message) => {
                    if (message.isLoading || !message.text) return acc;

                    if (message.role === "user") {
                        acc.push({ role: "user", content: message.text });
                    } else if (message.role === "bot") {
                        acc.push({ role: "assistant", content: message.text });
                    }

                    return acc;
                }, []),
                { role: "user", content: message },
            ],
        };

        setDisabled(true);
        setMessages([
            ...messages,
            { role: "user", text: message },
            { role: "bot", isLoading: true }
        ]);

        try {
            const completion = await chatApi.postConversation(conversation);
            setMessages([
                ...messages,
                { role: "user", text: message },
                { role: "bot", text: completion.content }
            ]);
        } catch (e) {
            setMessages([
                ...messages,
                { role: "user", text: message },
                { role: "system", text: "Something went wrong. Please try again." }
            ]);
        } finally {
            setDisabled(false);
        }
    };

    return (
        <Box sx={{ display: "flex", height: 1, flexDirection: "column", alignItems: "stretch", flex: "1 1 0%" }}>
            <Messages sx={{ flex: "1 1 0%" }} messages={messages} />
            <ChatInput disabled={disabled} onSubmit={onMessageSend} />
        </Box>
    );
}