import { Box, Skeleton, Stack, SxProps, styled } from '@mui/material';
import { red } from '@mui/material/colors';
import * as React from 'react';

export type Role = "user" | "bot" | "system";

export type Message = {
    role: Role;
    isLoading?: boolean;
    text?: string;
};

type Props = {
    messages: Message[];
    sx?: SxProps;
};

const Bubble = styled("div")<{ role: Role }>(({ role, theme }) => {
    let bg: string;
    let align: string;
    switch (role) {
        case "user":
            bg = theme.palette.primary.main;
            align = "flex-end";
            break;
        case "bot":
            bg = theme.palette.grey[200];
            align = "flex-start";
            break;
        case "system":
            bg = red[100];
            align = "center";
            break;
    }

    return ({
        marginTop: '1em',
        marginBottom: '1em',
        borderRadius: '1.15rem',
        lineHeight: 1.25,
        maxWidth: '75%',
        padding: '0.75rem 0.875rem',
        position: 'relative',
        wordWrap: 'break-word',
        backgroundColor: bg,
        color: theme.palette.getContrastText(bg),
        alignSelf: align,
        whiteSpace: 'pre-line',
    });
});

export default function Messages(props: Props) {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto", ...props.sx }}>
            {props.messages.map((message, index) => (
                <Bubble key={index} role={message.role}>
                    {message.isLoading 
                        ? (
                            <Stack direction="row" spacing={0.5}>
                                <Skeleton variant="circular" width={10} height={10} />
                                <Skeleton variant="circular" width={10} height={10} />
                                <Skeleton variant="circular" width={10} height={10} />
                            </Stack>
                        ): (
                            <>
                                {message.text}
                            </>
                        )}
                </Bubble>
            ))}
            <div ref={(el) => el?.scrollIntoView({ behavior: 'smooth' })} />
        </Box>
    );
}
