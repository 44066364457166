import axios from "axios";

export type Message = {
    role: "user" | "assistant";
    content: string;
};

export type Conversation = {
    messages: Message[];
};

const instance = axios.create({
    baseURL: '/api',
});

export async function postConversation(conversation: Conversation): Promise<Message> {
    const response = await instance.post<Message>('/conversation', conversation);

    return response.data;
}
