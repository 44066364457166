import * as React from 'react';
import { IconButton, InputAdornment, TextField, styled } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

type Props = {
    onSubmit: (message: string) => void;
    disabled?: boolean;
};

const Form = styled('form')(({ theme }) => ({
    paddingTop: theme.spacing(1),
}));

export default function ChatInput(props: Props) {
    const [message, setMessage] = React.useState<string>('');
    const formRef = React.useRef<HTMLFormElement>(null);

    return (
        <Form ref={formRef} onSubmit={(e) => {
            e.preventDefault();
            if (props.disabled) return;

            props.onSubmit(message);
            setMessage('');
        }}>
            <TextField
                fullWidth
                multiline
                disabled={props.disabled}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        formRef.current?.requestSubmit();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit" disabled={!message || props.disabled}>
                                <SendRoundedIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Form>
    );
}
